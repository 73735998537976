import { useStaticQuery, graphql } from 'gatsby'
import dedupe from 'dedupe'

export const useBlogBooks = () => {
  const { allArticleBook } = useStaticQuery(booksQuery)
  return allArticleBook.nodes
    ? dedupe(allArticleBook.nodes, node => node.slug)
    : null
}

const booksQuery = graphql`
  query allArticleBookQuery {
    allArticleBook {
      nodes {
        ...ArticleBook
      }
    }
  }
`
