import React from 'react'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import CardList from '@components/CardList'
import { useBlogBooks } from '@helpers-blog'

export default () => {
  const books = useBlogBooks()

  return (
    <>
      <Seo title='Azure e-Books series' />
      <Divider />
      <Stack effect='fadeInDown'>
        <Main>
          <PageTitle
            header='Azure e-Books series'
            subheader='Explore these Microsoft e-books and get everything from the basics to deep-dive information on the Azure. All these e-books are published free of charge by Azure Cloud Resource Center.'
          />
          <Divider />
          <CardList
            nodes={books}
            variant={['horizontal-md', 'horizontal-lg']}
            limit={10}
            columns={[1, 2, 2, 2]}
          />
        </Main>
      </Stack>
    </>
  )
}
